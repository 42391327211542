<template>
  <div
    :class="[
      'imelink-contact-list',
      `imelink-contact-list--${this.control.currentTab}`,
      {
        'imelink-contact-list--selection':
          this.control.selectionMode &&
          (this.control.selectionSource == 'joingroup' ||
            this.control.selectionSource == 'creategroup'),
      },
    ]"
    :style="{
      width: !showDefaultWindow ? '100%' : '',
      height: !showDefaultWindow ? '100%' : '',
    }"
  >
    <template v-if="showDefaultWindow">
      <template v-if="name == 'records'">
        <div
          v-for="(contact, index) in control.records"
          :key="contact.id + index + contact.unread"
          :class="
            contact.id +
              index +
              `${contact.collected ? 'Collected' : 'NotCollected'}`
          "
        >
          <imelink-contact
            :class="{ 'imelink-contact--self': contact.id == control.user.id }"
            :is-active="
              control.currentContact.id == contact.id &&
                control.selectionMode != true
            "
            :data="contact"
            :default-selected="!!control.selectionData[contact.id]"
            :is-checkbox="control.selectionMode"
            ref="contact"
            @click="control._changeMessageView(contact)"
          >
            <template #isInMeeting>
              <!-- <span class="isInMeeting_box" v-if="contact.isInMeeting"></span>  -->
              <span
                class="isInMeeting_box"
                v-if="
                  $store.state.multimedia_meetingmode.meetingGroupId ==
                    contact.id &&
                    $store.state.multimedia_meetingmode.meetingWindowStatus
                "
              ></span>
            </template>
            <template #action>
              <div style="display: flex; align-items: center">
                <!-- 显示讨论组分类图标 -->
                <template v-if="contact.isGroup && stillInTheGroup(contact)">
                  <div
                    style="
                          background: #ffb320;
                          border-radius: 50%;
                          width: 18px;
                          height: 18px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          margin-top: -1px;
                        "
                    :title="$t('classification')"
                    :style="{
                      marginRight:
                        name == 'groups' &&
                        (contact.groupOwnerId == control.user.id ||
                          contact.collected)
                          ? '42px'
                          : name == 'groups'
                          ? '16px'
                          : '0',
                    }"
                    @click.stop="handleMoveGroup(contact, index)"
                  >
                    <span
                      class="iconfont_Me icon-wenjianjia"
                      style="font-size: 14px; color: #fff"
                    ></span>
                  </div>
                </template>
                <template v-if="contact.isUser">
                  <div
                    :style="{
                      width: '18px',
                      height: '18px',
                      display: 'flex',
                      'align-items': 'center',
                      'justify-content': 'center',
                    }"
                    @click.stop="handleOpenRemark(contact)"
                  >
                    <i
                      class="el-icon--tag"
                      :style="{
                        'font-size': '18px',
                        margin: 0,
                      }"
                      style="color: #52bd68; font-size: 24px"
                    ></i>
                  </div>
                </template>
                <i
                  class="el-icon-error"
                  style="color: #f56c6c"
                  @click.stop="handleClose(contact, index)"
                  :title="$t('delete')"
                ></i>
              </div>
            </template>
          </imelink-contact>
        </div>
      </template>
      <template v-if="name == 'groups'">
        <div
          v-for="(contact, index) in control.groups"
          :class="contact.id + index"
          :key="contact.id + index"
        >
          <imelink-contact
            :class="{
              'imelink-contact--self': contact.id == control.user.id,
            }"
            :data="contact"
            ref="contact"
            @click="control._changeMessageView(contact)"
          >
            <template #action>
              <div style="display: flex; align-items: center">
                <!-- 显示讨论组分类图标 -->
                <template v-if="contact.isGroup">
                  <div
                    style="
                      background: #ffb320;
                      border-radius: 50%;
                      width: 18px;
                      height: 18px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      margin-top: -1px;
                    "
                    :title="$t('classification')"
                    :style="{
                      marginRight:
                        name == 'groups' && contact.collected
                          ? '42px'
                          : name == 'groups'
                          ? '16px'
                          : '0',
                    }"
                    @click.stop="handleMoveGroup(contact, index)"
                  >
                    <span
                      class="iconfont_Me icon-wenjianjia"
                      style="font-size: 14px; color: #fff"
                    ></span>
                  </div>
                </template>
              </div>
            </template>
          </imelink-contact>
        </div>
      </template>
      <template v-if="name === 'friends'">
        <div class="deleteContactStatus" v-if="userClickAddToTheContact">
          <span
            class="iconfont_Me icon-left iconStyle"
            @click.stop="deleteContactStatus"
          ></span>
        </div>
        <div
          v-for="(contact, index) in visiblePageData"
          :key="contact.id + index"
          :class="contact.id + index"
        >
          <!-- 好友列表昵称开头字母 -->
          <p v-once class="imelink-contact-list__label">
            {{ uniqueIndex(contact.index) }}
          </p>
          <imelink-contact
            :class="{ 'imelink-contact--self': contact.id == control.user.id }"
            :is-active="
              control.currentContact.id == contact.id &&
                control.selectionMode != true
            "
            :type="name"
            :data="contact"
            :default-selected="!!control.selectionData[contact.id]"
            :is-checkbox="control.selectionMode"
            ref="contact"
            @click="control._changeMessageView(contact)"
            @showBlacklist="showBlacklist"
          >
            <!-- 当他是好友列表的时候，显示标签 -->
            <template #action>
              <i
                class="el-icon--tag"
                style="color: #52bd68; font-size: 24px"
                @click.stop="handleOpenRemark(contact)"
              ></i>
            </template>
          </imelink-contact>
        </div>
      </template>
      <template v-if="name === 'adds'">
        <div class="imelink-contact-list__preview">
          <avatar
            v-for="contact in selectionData"
            :key="contact.id"
            :src="contact.avatar || ''"
            size="40px"
          ></avatar>
        </div>
        <div
          v-for="(contact, index) in visiblePageData2"
          :key="contact.id + index"
          :class="contact.id + index"
        >
          <!-- 好友列表昵称开头字母 -->
          <p v-once class="imelink-contact-list__label">
            {{ uniqueIndex(contact.index) }}
          </p>
          <imelink-contact
            :class="{ 'imelink-contact--self': contact.id == control.user.id }"
            :is-active="
              control.currentContact.id == contact.id &&
                control.selectionMode != true
            "
            :data="contact"
            :default-selected="!!control.selectionData[contact.id]"
            :is-checkbox="control.selectionMode"
            ref="contact"
            @click="control._changeMessageView(contact)"
            @change-selected="changeSelected"
          >
            <!-- 当他是好友列表的时候，显示标签 -->
            <template #action>
              <i
                class="el-icon--tag"
                style="color: #52bd68; font-size: 24px"
                @click.stop="handleOpenRemark(contact)"
              ></i>
            </template>
          </imelink-contact>
        </div>
      </template>
    </template>

    <transition name="floatingWindow">
      <template v-if="name === 'friends' && needShowBlackList">
        <div class="floatingWindowBox">
          <div class="listHeader">
            <div class="closeWindowIcon" @click.stop="closeBlackList">
              <el-image
                style="width: 100%; height: 100%"
                :src="require('../../../assets/images/im/closeBlackList.png')"
                :fit="'cover'"
              ></el-image>
            </div>
          </div>
          <div class="listContent">
            <template v-if="blackListData.length !== 0">
              <BlackListItem
                v-for="item in blackListData"
                :key="item.providerId"
                :item="item"
                @refreshBlackList="showBlacklist"
              ></BlackListItem>
            </template>
            <div class="placeholderMap" v-else>
              <PlaceholderMap :placeholderType="2"></PlaceholderMap>
            </div>
          </div>
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
import DiscussionGroupPopover from "./components/discussionGroupPopover";
import ImelinkContact from "./contact-item";
import BlackListItem from "./blackList-item";
import * as RESTApi from "@/api/rest";
import PlaceholderMap from "../../../newVersion/components/placeholderMap";
import { allCollect } from "@/api/newVersion/discussionGroupClassification";
export default {
  name: "ContactList",
  inject: ["control"],
  components: {
    BlackListItem,
    ImelinkContact,
    PlaceholderMap,
    DiscussionGroupPopover,
  },
  data() {
    return {
      // 好友列表依次加载
      visiblePageData: [],
      // 添加选项列表
      visiblePageData2: [],
      // 每次加载的数据
      pageItemData: 50,
      pageItemData2: 50,
      existsIndex: [],
      // 黑名单列表数据
      blackListData: [],
    };
  },
  props: {
    name: {
      type: String,
      validator(val) {
        return ["friends", "records", "adds", "groups"].includes(val);
      },
      default: "",
    },
    //已选择的联系人
    selectionData: {},
    // 是否展示黑名单列表
    needShowBlackList: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showDefaultWindow() {
      return !this.needShowBlackList;
    },
    groups() {
      return Array.isArray(this.control.groups) ? this.control.groups : [];
    },
    userClickAddToTheContact() {
      return this.$store.state.ipTelephone.userClickAddToTheContact;
    },
  },
  watch: {
    "control.friends": {
      async handler(val, old) {
        if (this.name == "friends") {
          this.existsIndex = [];
          this.visiblePageData = [];
          // 好友列表里切第一部分展示，滚动触底后再切下一部分
          this.visiblePageData = val.slice(0, this.pageItemData);
          // 这里要在队列第二个插入一个群组按钮
          try {
            if (this.control.searchValue == "") {
              this.visiblePageData.splice(
                1,
                0,
                { id: 1, isContactsButton: true },
                { id: 3, isBlackListButton: true }
              );
            }
          } catch (e) {}
        }
      },
      deep: true,
      immediate: true,
    },
    "control.adds": {
      handler(val) {
        if (this.name == "adds") {
          this.existsIndex = [];
          // 好友列表里切第一部分展示，滚动触底后再切下一部分
          this.visiblePageData2 = val.slice(0, this.pageItemData2);
        }
      },
      deep: true,
      immediate: true,
    },

    // 监听黑名单是否需要刷新
    "$store.state.imStore.blackListChangeStatus": {
      async handler(val, old) {
        // 如果开启了黑名单的话，就刷新黑名单
        if (
          val &&
          val !== old &&
          this.name === "friends" &&
          this.needShowBlackList
        ) {
          await this.showBlacklist();
          this.$nextTick(() => {
            this.$store.commit("setBlackListChangeStatus", false);
          });
        }
        // 监听到需要刷新黑名单，必须刷新好友列表
        if (val && val !== old && this.name === "friends") {
          this.control.tabChange("friends");
          this.$nextTick(() => {
            this.$store.commit("setBlackListChangeStatus", false);
          });
        }
      },
      deep: true,
    },
    // 监听到有好友的黑名单列表关闭了，就刷新列表
    needShowBlackList: {
      handler(val, old) {
        if (!val && val !== old && this.name === "friends") {
          this.control.tabChange("friends");
          this.$nextTick(() => {
            this.$store.commit("setNeedRefreshBlackListStatus", false);
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.existsIndex = [];
    // 绑定选择事件
    if (this.name == "adds") {
      this.control.$on("notifyContactChangeSelected", (val, data, name) => {
        if (name == this.name) return;
        const contact = (this.$refs.contact || []).find(
          (v) => v.data.id == data.id
        );
        if (contact) contact.change(val);
      });
    }
  },
  methods: {
    // 用户是否还在群里
    stillInTheGroup(contact) {
      let stillIn = true;
      if (contact.isGroup && contact.messageData) {
        // 如果是退出群组，并且退出的是用户自己
        if (
          contact.messageData.actionType == 4 &&
          contact.messageData.removeID == this.$store.getters.userInfo.id
        ) {
          stillIn = false;
        }
        if (!contact.groupOwnerId) {
          stillIn = false;
        }
      }
      return stillIn;
    },
    // 删除来自直接添加到通讯录的记录
    deleteContactStatus() {
      this.$store.commit("setUserClickAddToTheContact", null);
    },
    // 滚动即将触底，就加载下一页
    _listenerFn(el, mode) {
      if (
        this.visiblePageData.length - 3 === this.control["friends"].length &&
        mode == "friends"
      )
        return;
      if (
        this.visiblePageData2.length === this.control["adds"].length &&
        mode == "adds"
      )
        return;
      if (
        el.scrollTop + el.clientHeight + el.scrollHeight / 3 >=
        el.scrollHeight
      ) {
        let sliceIndex =
          this.pageItemData *
          Math.floor(this.visiblePageData.length / this.pageItemData);
        this.visiblePageData = this.visiblePageData.concat(
          this.control["friends"].slice(
            sliceIndex,
            sliceIndex + this.pageItemData
          )
        );
        // 滑动触底过后去重
        this.visiblePageData = Array.from(new Set(this.visiblePageData));
        this.$nextTick(() => {
          // 当邀请好友加入群聊的时候
          if (this.control.selectionSource === "joingroup") {
            this.control.getCurrentGroupInfo().members.forEach((item) => {
              // 获取群聊的群员，禁止点击
              let el = null;
              try {
                el = this.control
                  .getContactListComponentByType("friends")
                  .getContactEl(item.id);
              } catch (error) {
                console.log("邀请好友加入群聊_1");
              }
              if (el) {
                // 如果是群友，则禁止点击
                if (
                  Array.from(el.classList).indexOf("imelink-contact--self") ===
                  -1
                ) {
                  el.classList.add("imelink-contact--self");
                }
              }
            });
          }
        });
      }

      /** adds */
      if (
        el.scrollTop + el.clientHeight + el.scrollHeight / 3 >=
        el.scrollHeight
      ) {
        let sliceIndex =
          this.pageItemData2 *
          Math.floor(this.visiblePageData2.length / this.pageItemData2);
        this.visiblePageData2 = this.visiblePageData2.concat(
          this.control["adds"].slice(
            sliceIndex,
            sliceIndex + this.pageItemData2
          )
        );
        // 滑动触底过后去重
        this.visiblePageData2 = Array.from(new Set(this.visiblePageData2));
        this.$nextTick(() => {
          // 当邀请好友加入群聊的时候
          if (this.control.selectionSource === "joingroup") {
            this.control.getCurrentGroupInfo().members.forEach((item) => {
              // 获取群聊的群员，禁止点击
              let el = null;
              try {
                el = this.control
                  .getContactListComponentByType("adds")
                  .getContactEl(item.id);
              } catch (error) {
                console.log("邀请好友加入群聊_2");
              }
              if (el) {
                // 如果是群友，则禁止点击
                if (
                  Array.from(el.classList).indexOf("imelink-contact--self") ===
                  -1
                ) {
                  el.classList.add("imelink-contact--self");
                }
              }
            });
          }
        });
      }
    },
    // 关闭黑名单列表
    closeBlackList() {
      this.control.needShowBlackList = false;
    },
    // 展示黑名单列表
    async showBlacklist() {
      let result = await RESTApi.getBlacklistData();
      if (result.code == 200) {
        this.blackListData = result.data.data;
        this.$nextTick(() => {
          this.control.needShowBlackList = true;
        });
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    uniqueIndex(index) {
      if (this.existsIndex.includes(index)) {
        return "";
      } else {
        this.existsIndex.push(index);
        return index;
      }
    },
    clearSelected() {
      this.$refs.contact.forEach((component) => {
        component.change(false);
      });
    },
    handleClose(contact, index) {
      this.$msgbox({
        title: "",
        message: <i class="el-icon-delete" />,
        center: true,
        customClass: "imelink-delrecord-confirm",
        confirmButtonText: " ",
        cancelButtonText: " ",
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: "el-icon-close",
        confirmButtonClass: "el-icon-check",
        beforeClose: (action, instance, done) => {
          if (action == "confirm") {
            instance.confirmButtonLoading = true;
            const complete = () => {
              instance.confirmButtonLoading = false;
            };
            this.control.$emit("close-record", contact, {
              success: () => {
                this.control.records.splice(index, 1);
                this.control.currentContact = {};
                this.control.messageAll = {};
                try {
                  if (this.control.groupsInfo[contact.id]) {
                    delete this.control.groupsInfo[contact.id];
                  }
                } catch (e) {}
                complete();
                done();
              },
              error: () => {
                complete();
              },
            });
          } else {
            done();
          }
          return false;
        },
      });
    },
    async handleMoveGroup(contact) {
      let result = await allCollect();
      result.data.data.forEach((item) => {
        if (item.nodeData == contact.id) {
          this.$store.commit("setExistingClassifiedGroup", item);
        }
      });
      this.$store.commit(
        "setExistingClassifiedGroupInfo",
        _.cloneDeep(contact)
      );
      this.$emit("handlerMoveGroup");
    },
    handleOpenRemark(contact) {
      contact.remarks = contact.remarks || [];
      this.control.$refs.contactRemark.open(contact);
    },
    changeSelected(val, data) {
      if (val == true) {
        this.$set(this.control.selectionData, data.id, data);
      } else {
        this.$delete(this.control.selectionData, data.id);
      }
      this.control.$emit("notifyContactChangeSelected", val, data, this.name);
    },
    getContactEl(contactId) {
      const component = this.$refs.contact.find(
        (component) => component.data.id == contactId
      );
      if (component) return component.$el;
    },
    click(contactId) {
      const el = this.getContactEl(contactId);
      if (el) {
        el.$el.click();
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';

.floatingWindow-enter-active, .floatingWindow-leave-active {
  transition: transform 0.2s;
}

.floatingWindow-enter, .floatingWindow-leave-to {
  transform: translate3d(50px, 0px, 0);
}

.imelink-contact-list {
  position: relative;
  .deleteContactStatus {
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .iconStyle {
      font-size: 16px;
      color: #000000;
      font-weight: bold;
      cursor: pointer;
    }
  }
  .floatingWindowBox {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    background: #FFFFFF;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;

    .listHeader {
      width: 100%;
      height: 48px;
      flex-shrink: 0;
      box-sizing: border-box;
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .closeWindowIcon {
        width: 24px;
        height: 24px;
      }
    }

    .listContent {
      flex: 1;
      width: 100%;
      min-height: 0;
      overflow-x: hidden;
      overflow-y: auto;
      scrollbar-theme(#dedede, #f9f9f9);

      .placeholderMap {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
<style lang="stylus">
@import '~styles/utils/index';

.isInMeeting_box {
  width: 20px;
  height: 20px;
  margin-right: 20px;
  display: inline-block;
  background: url('../../../assets/images/multiplayer/isMeeting.png') no-repeat;
  background-size: 100% 100%;
}

.imelink-contact-list--selection.imelink-contact-list--friends .imelink-contact--self {
  pointer-events: none;
  user-select: none;

  .imelink-contact__success {
    color: #999 !important;
  }

  .imelink-contact__success:before {
    content: '\E89D';
  }
}

.imelink-contact-list__label {
  font-size: 12px;
  background: #f6f6f6;
  line-height: 24px;
  text-indent: 10px;
  color: #999;
}

.imelink-delrecord-confirm {
  width: 220px;

  .el-icon-delete, .el-icon-document-copy {
    font-size: 38px;
    position: relative;
    top: -12px;
  }

  b {
    font-size: 30px;
    position: relative;
    top: -12px;
    padding-left: 4px;
  }

  span {
    display: none;
  }
}
</style>
