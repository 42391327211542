var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "imelink-contact-list",
        `imelink-contact-list--${this.control.currentTab}`,
        {
          "imelink-contact-list--selection":
            this.control.selectionMode &&
            (this.control.selectionSource == "joingroup" ||
              this.control.selectionSource == "creategroup")
        }
      ],
      style: {
        width: !_vm.showDefaultWindow ? "100%" : "",
        height: !_vm.showDefaultWindow ? "100%" : ""
      }
    },
    [
      _vm.showDefaultWindow
        ? [
            _vm.name == "records"
              ? _vm._l(_vm.control.records, function(contact, index) {
                  return _c(
                    "div",
                    {
                      key: contact.id + index + contact.unread,
                      class:
                        contact.id +
                        index +
                        `${contact.collected ? "Collected" : "NotCollected"}`
                    },
                    [
                      _c("imelink-contact", {
                        ref: "contact",
                        refInFor: true,
                        class: {
                          "imelink-contact--self":
                            contact.id == _vm.control.user.id
                        },
                        attrs: {
                          "is-active":
                            _vm.control.currentContact.id == contact.id &&
                            _vm.control.selectionMode != true,
                          data: contact,
                          "default-selected": !!_vm.control.selectionData[
                            contact.id
                          ],
                          "is-checkbox": _vm.control.selectionMode
                        },
                        on: {
                          click: function($event) {
                            return _vm.control._changeMessageView(contact)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "isInMeeting",
                              fn: function() {
                                return [
                                  _vm.$store.state.multimedia_meetingmode
                                    .meetingGroupId == contact.id &&
                                  _vm.$store.state.multimedia_meetingmode
                                    .meetingWindowStatus
                                    ? _c("span", {
                                        staticClass: "isInMeeting_box"
                                      })
                                    : _vm._e()
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "action",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center"
                                      }
                                    },
                                    [
                                      contact.isGroup &&
                                      _vm.stillInTheGroup(contact)
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  background: "#ffb320",
                                                  "border-radius": "50%",
                                                  width: "18px",
                                                  height: "18px",
                                                  display: "flex",
                                                  "align-items": "center",
                                                  "justify-content": "center",
                                                  "margin-top": "-1px"
                                                },
                                                style: {
                                                  marginRight:
                                                    _vm.name == "groups" &&
                                                    (contact.groupOwnerId ==
                                                      _vm.control.user.id ||
                                                      contact.collected)
                                                      ? "42px"
                                                      : _vm.name == "groups"
                                                      ? "16px"
                                                      : "0"
                                                },
                                                attrs: {
                                                  title: _vm.$t(
                                                    "classification"
                                                  )
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.handleMoveGroup(
                                                      contact,
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "iconfont_Me icon-wenjianjia",
                                                  staticStyle: {
                                                    "font-size": "14px",
                                                    color: "#fff"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        : _vm._e(),
                                      contact.isUser
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                style: {
                                                  width: "18px",
                                                  height: "18px",
                                                  display: "flex",
                                                  "align-items": "center",
                                                  "justify-content": "center"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.handleOpenRemark(
                                                      contact
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon--tag",
                                                  staticStyle: {
                                                    color: "#52bd68",
                                                    "font-size": "24px"
                                                  },
                                                  style: {
                                                    "font-size": "18px",
                                                    margin: 0
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        : _vm._e(),
                                      _c("i", {
                                        staticClass: "el-icon-error",
                                        staticStyle: { color: "#f56c6c" },
                                        attrs: { title: _vm.$t("delete") },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.handleClose(
                                              contact,
                                              index
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    2
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                })
              : _vm._e(),
            _vm.name == "groups"
              ? _vm._l(_vm.control.groups, function(contact, index) {
                  return _c(
                    "div",
                    { key: contact.id + index, class: contact.id + index },
                    [
                      _c("imelink-contact", {
                        ref: "contact",
                        refInFor: true,
                        class: {
                          "imelink-contact--self":
                            contact.id == _vm.control.user.id
                        },
                        attrs: { data: contact },
                        on: {
                          click: function($event) {
                            return _vm.control._changeMessageView(contact)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "action",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center"
                                      }
                                    },
                                    [
                                      contact.isGroup
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  background: "#ffb320",
                                                  "border-radius": "50%",
                                                  width: "18px",
                                                  height: "18px",
                                                  display: "flex",
                                                  "align-items": "center",
                                                  "justify-content": "center",
                                                  "margin-top": "-1px"
                                                },
                                                style: {
                                                  marginRight:
                                                    _vm.name == "groups" &&
                                                    contact.collected
                                                      ? "42px"
                                                      : _vm.name == "groups"
                                                      ? "16px"
                                                      : "0"
                                                },
                                                attrs: {
                                                  title: _vm.$t(
                                                    "classification"
                                                  )
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.handleMoveGroup(
                                                      contact,
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "iconfont_Me icon-wenjianjia",
                                                  staticStyle: {
                                                    "font-size": "14px",
                                                    color: "#fff"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                })
              : _vm._e(),
            _vm.name === "friends"
              ? [
                  _vm.userClickAddToTheContact
                    ? _c("div", { staticClass: "deleteContactStatus" }, [
                        _c("span", {
                          staticClass: "iconfont_Me icon-left iconStyle",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.deleteContactStatus.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        })
                      ])
                    : _vm._e(),
                  _vm._l(_vm.visiblePageData, function(contact, index) {
                    return _c(
                      "div",
                      { key: contact.id + index, class: contact.id + index },
                      [
                        _vm._o(
                          _c(
                            "p",
                            { staticClass: "imelink-contact-list__label" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.uniqueIndex(contact.index)) +
                                  "\n        "
                              )
                            ]
                          ),
                          0,
                          contact.id + index
                        ),
                        _c("imelink-contact", {
                          ref: "contact",
                          refInFor: true,
                          class: {
                            "imelink-contact--self":
                              contact.id == _vm.control.user.id
                          },
                          attrs: {
                            "is-active":
                              _vm.control.currentContact.id == contact.id &&
                              _vm.control.selectionMode != true,
                            type: _vm.name,
                            data: contact,
                            "default-selected": !!_vm.control.selectionData[
                              contact.id
                            ],
                            "is-checkbox": _vm.control.selectionMode
                          },
                          on: {
                            click: function($event) {
                              return _vm.control._changeMessageView(contact)
                            },
                            showBlacklist: _vm.showBlacklist
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "action",
                                fn: function() {
                                  return [
                                    _c("i", {
                                      staticClass: "el-icon--tag",
                                      staticStyle: {
                                        color: "#52bd68",
                                        "font-size": "24px"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.handleOpenRemark(contact)
                                        }
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  })
                ]
              : _vm._e(),
            _vm.name === "adds"
              ? [
                  _c(
                    "div",
                    { staticClass: "imelink-contact-list__preview" },
                    _vm._l(_vm.selectionData, function(contact) {
                      return _c("avatar", {
                        key: contact.id,
                        attrs: { src: contact.avatar || "", size: "40px" }
                      })
                    }),
                    1
                  ),
                  _vm._l(_vm.visiblePageData2, function(contact, index) {
                    return _c(
                      "div",
                      { key: contact.id + index, class: contact.id + index },
                      [
                        _vm._o(
                          _c(
                            "p",
                            { staticClass: "imelink-contact-list__label" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.uniqueIndex(contact.index)) +
                                  "\n        "
                              )
                            ]
                          ),
                          1,
                          contact.id + index
                        ),
                        _c("imelink-contact", {
                          ref: "contact",
                          refInFor: true,
                          class: {
                            "imelink-contact--self":
                              contact.id == _vm.control.user.id
                          },
                          attrs: {
                            "is-active":
                              _vm.control.currentContact.id == contact.id &&
                              _vm.control.selectionMode != true,
                            data: contact,
                            "default-selected": !!_vm.control.selectionData[
                              contact.id
                            ],
                            "is-checkbox": _vm.control.selectionMode
                          },
                          on: {
                            click: function($event) {
                              return _vm.control._changeMessageView(contact)
                            },
                            "change-selected": _vm.changeSelected
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "action",
                                fn: function() {
                                  return [
                                    _c("i", {
                                      staticClass: "el-icon--tag",
                                      staticStyle: {
                                        color: "#52bd68",
                                        "font-size": "24px"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.handleOpenRemark(contact)
                                        }
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  })
                ]
              : _vm._e()
          ]
        : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "floatingWindow" } },
        [
          _vm.name === "friends" && _vm.needShowBlackList
            ? [
                _c("div", { staticClass: "floatingWindowBox" }, [
                  _c("div", { staticClass: "listHeader" }, [
                    _c(
                      "div",
                      {
                        staticClass: "closeWindowIcon",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.closeBlackList.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c("el-image", {
                          staticStyle: { width: "100%", height: "100%" },
                          attrs: {
                            src: require("../../../assets/images/im/closeBlackList.png"),
                            fit: "cover"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "listContent" },
                    [
                      _vm.blackListData.length !== 0
                        ? _vm._l(_vm.blackListData, function(item) {
                            return _c("BlackListItem", {
                              key: item.providerId,
                              attrs: { item: item },
                              on: { refreshBlackList: _vm.showBlacklist }
                            })
                          })
                        : _c(
                            "div",
                            { staticClass: "placeholderMap" },
                            [
                              _c("PlaceholderMap", {
                                attrs: { placeholderType: 2 }
                              })
                            ],
                            1
                          )
                    ],
                    2
                  )
                ])
              ]
            : _vm._e()
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }