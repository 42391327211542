var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "right",
        "popper-class": "blackListItemPopover",
        "visible-arrow": false,
        offset: 15,
        "popper-options": {
          removeOnDestroy: true
        },
        trigger: "hover"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "listItem",
          attrs: { slot: "reference" },
          slot: "reference"
        },
        [
          _c(
            "div",
            { staticClass: "profilePhoto" },
            [
              _c("AvatarDisplay", {
                attrs: {
                  providerId: _vm.item.providerId,
                  path: _vm.item.profilePhoto,
                  international: _vm.item.country ? _vm.item.country : 0
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "profileInfo" },
            [
              _c(
                "span",
                {
                  staticClass: "profileName",
                  class: _vm.item.vipStatus ? "vipName" : ""
                },
                [
                  _vm._v(_vm._s(_vm.item.name) + "\n        "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: _vm.$t("paidMember"),
                        placement: "bottom",
                        "popper-class": "me-tooltip-effect"
                      }
                    },
                    [
                      _vm.item.vipStatus
                        ? _c("el-image", {
                            staticStyle: {
                              width: "17px",
                              height: "14px",
                              "margin-left": "3px"
                            },
                            attrs: {
                              src: require("@/assets/images/pay/masonry.png"),
                              fit: "fill"
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.handleClickVip(_vm.item.id)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._l(_vm.remarkNames, function(item) {
                return _c(
                  "el-tag",
                  {
                    key: item,
                    staticClass: "blackListRemark",
                    attrs: {
                      hit: "",
                      effect: "plain",
                      "disable-transitions": "",
                      size: "mini"
                    }
                  },
                  [_vm._v(_vm._s(item))]
                )
              })
            ],
            2
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "deleteButton",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.deleteItem.apply(null, arguments)
            }
          }
        },
        [
          _c("el-image", {
            staticStyle: { width: "15px", height: "16px" },
            attrs: {
              src: require("../../../assets/images/im/deleteIcon.png"),
              fit: "fill"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }