<template>
  <el-popover
    placement="right"
    popper-class="blackListItemPopover"
    :visible-arrow="false"
    :offset="15"
    :popper-options="{
      removeOnDestroy: true,
    }"
    trigger="hover"
  >
    <div class="listItem" slot="reference">
      <div class="profilePhoto">
        <AvatarDisplay
          :providerId="item.providerId"
          :path="item.profilePhoto"
          :international="item.country ? item.country : 0"
        ></AvatarDisplay>
      </div>
      <div class="profileInfo">
        <span class="profileName" :class="item.vipStatus ? 'vipName' : ''"
          >{{ item.name }}
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('paidMember')"
            placement="bottom"
            popper-class="me-tooltip-effect"
          >
            <el-image
              style="width: 17px; height: 14px;margin-left: 3px;"
              v-if="item.vipStatus"
              :src="require('@/assets/images/pay/masonry.png')"
              fit="fill"
              @click.stop="handleClickVip(item.id)"
            >
            </el-image>
          </el-tooltip>
        </span>
        <el-tag
          hit
          effect="plain"
          disable-transitions
          size="mini"
          class="blackListRemark"
          v-for="item in remarkNames"
          :key="item"
          >{{ item }}</el-tag
        >
      </div>
    </div>
    <div class="deleteButton" @click.stop="deleteItem">
      <el-image
        style="width: 15px; height: 16px"
        :src="require('../../../assets/images/im/deleteIcon.png')"
        :fit="'fill'"
      ></el-image>
    </div>
  </el-popover>
</template>
<script>
import AvatarDisplay from "../../../newVersion/components/avatarDisplay/index";
import * as RESTApi from "@/api/rest";
export default {
  components: {
    AvatarDisplay,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      // 删除请求状态，防止连续点击
      deleteRequestFlag: false,
    };
  },
  computed: {
    // 备注集合
    remarkNames() {
      let remarkNames = [];
      if (this.item.remarkName !== "") {
        remarkNames = this.item.remarkName.split(",");
      }
      return remarkNames;
    },
  },
  methods: {
    // 点击删除这个对象
    async deleteItem() {
      if (!this.deleteRequestFlag) {
        this.deleteRequestFlag = true;
        let result = await RESTApi.removeBlacklist(this.item.providerId);
        if (result.code == 200) {
          this.$store.commit("setNeedRefreshBlackListStatus", true);
          this.$emit("refreshBlackList");
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
        this.$nextTick(() => {
          this.deleteRequestFlag = false;
        });
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.listItem
  width 100%;
  height 61px;
  display flex;
  cursor pointer;
  padding 0 16px;
  align-items center;
  box-sizing border-box;
  justify-content flex-start;
  border-bottom 1px solid #F6F6F6;
  &:hover
    background #ebebeb;
  .profilePhoto
    width 40px;
    height 40px;
    flex-shrink 0;
    margin-right 9px;
    overflow hidden;
  .profileInfo
    flex 1;
    min-width 0;
    height 40px;
    line-height 40px;
    overflow hidden;
    white-space nowrap;
    text-overflow ellipsis;
    .profileName
      // font-weight bold;
      color #1A051D;
      font-size 14px;
      margin-right 4px;
    .blackListRemark
      height 16px;
      margin-right 4px;
      line-height 14px;
      border-radius 8px;
      background #F2FCF8;
      border 1px solid #2FBF5D;
</style>
<style lang="stylus">
.blackListItemPopover
  min-width 48px !important;
  height 44px !important;
  margin-left -62px !important;
  padding 0 !important;
  box-sizing border-box !important;
  .deleteButton
    width 100%;
    height 100%;
    cursor pointer;
    display flex;
    align-items center;
    justify-content center;
    user-select none;
</style>
